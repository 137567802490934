import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import "popper.js/dist/popper.min"
import "bootstrap/dist/js/bootstrap.min.js"

import "bootstrap/dist/css/bootstrap.min.css"

export const onClientEntry = () => {
  // IntersectionObsrver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    require("intersection-observer")
  }
}
