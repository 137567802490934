// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-affiliate-anand-bikash-js": () => import("./../../../src/pages/affiliate/anand-bikash.js" /* webpackChunkName: "component---src-pages-affiliate-anand-bikash-js" */),
  "component---src-pages-affiliate-apna-complex-js": () => import("./../../../src/pages/affiliate/apna-complex.js" /* webpackChunkName: "component---src-pages-affiliate-apna-complex-js" */),
  "component---src-pages-affiliate-chandan-km-js": () => import("./../../../src/pages/affiliate/chandan-km.js" /* webpackChunkName: "component---src-pages-affiliate-chandan-km-js" */),
  "component---src-pages-affiliate-lagna-devi-js": () => import("./../../../src/pages/affiliate/lagna-devi.js" /* webpackChunkName: "component---src-pages-affiliate-lagna-devi-js" */),
  "component---src-pages-affiliate-latent-talent-js": () => import("./../../../src/pages/affiliate/latent-talent.js" /* webpackChunkName: "component---src-pages-affiliate-latent-talent-js" */),
  "component---src-pages-affiliate-lt-aadesh-js": () => import("./../../../src/pages/affiliate/lt-aadesh.js" /* webpackChunkName: "component---src-pages-affiliate-lt-aadesh-js" */),
  "component---src-pages-affiliate-lt-alok-js": () => import("./../../../src/pages/affiliate/lt-alok.js" /* webpackChunkName: "component---src-pages-affiliate-lt-alok-js" */),
  "component---src-pages-affiliate-lt-ashutosh-js": () => import("./../../../src/pages/affiliate/lt-ashutosh.js" /* webpackChunkName: "component---src-pages-affiliate-lt-ashutosh-js" */),
  "component---src-pages-affiliate-lt-babul-js": () => import("./../../../src/pages/affiliate/lt-babul.js" /* webpackChunkName: "component---src-pages-affiliate-lt-babul-js" */),
  "component---src-pages-affiliate-lt-barun-js": () => import("./../../../src/pages/affiliate/lt-barun.js" /* webpackChunkName: "component---src-pages-affiliate-lt-barun-js" */),
  "component---src-pages-affiliate-lt-direct-js": () => import("./../../../src/pages/affiliate/lt-direct.js" /* webpackChunkName: "component---src-pages-affiliate-lt-direct-js" */),
  "component---src-pages-affiliate-lt-mohammad-js": () => import("./../../../src/pages/affiliate/lt-mohammad.js" /* webpackChunkName: "component---src-pages-affiliate-lt-mohammad-js" */),
  "component---src-pages-affiliate-lt-neeraj-js": () => import("./../../../src/pages/affiliate/lt-neeraj.js" /* webpackChunkName: "component---src-pages-affiliate-lt-neeraj-js" */),
  "component---src-pages-affiliate-lt-neeraj-up-js": () => import("./../../../src/pages/affiliate/lt-neeraj-up.js" /* webpackChunkName: "component---src-pages-affiliate-lt-neeraj-up-js" */),
  "component---src-pages-affiliate-lt-office-1-js": () => import("./../../../src/pages/affiliate/lt-office1.js" /* webpackChunkName: "component---src-pages-affiliate-lt-office-1-js" */),
  "component---src-pages-affiliate-lt-office-2-js": () => import("./../../../src/pages/affiliate/lt-office2.js" /* webpackChunkName: "component---src-pages-affiliate-lt-office-2-js" */),
  "component---src-pages-affiliate-lt-office-3-js": () => import("./../../../src/pages/affiliate/lt-office3.js" /* webpackChunkName: "component---src-pages-affiliate-lt-office-3-js" */),
  "component---src-pages-affiliate-lt-office-4-js": () => import("./../../../src/pages/affiliate/lt-office4.js" /* webpackChunkName: "component---src-pages-affiliate-lt-office-4-js" */),
  "component---src-pages-affiliate-lt-roshan-js": () => import("./../../../src/pages/affiliate/lt-roshan.js" /* webpackChunkName: "component---src-pages-affiliate-lt-roshan-js" */),
  "component---src-pages-affiliate-lt-sawan-js": () => import("./../../../src/pages/affiliate/lt-sawan.js" /* webpackChunkName: "component---src-pages-affiliate-lt-sawan-js" */),
  "component---src-pages-affiliate-lt-sonu-js": () => import("./../../../src/pages/affiliate/lt-sonu.js" /* webpackChunkName: "component---src-pages-affiliate-lt-sonu-js" */),
  "component---src-pages-affiliate-lt-suraj-js": () => import("./../../../src/pages/affiliate/lt-suraj.js" /* webpackChunkName: "component---src-pages-affiliate-lt-suraj-js" */),
  "component---src-pages-affiliate-lt-vivek-js": () => import("./../../../src/pages/affiliate/lt-vivek.js" /* webpackChunkName: "component---src-pages-affiliate-lt-vivek-js" */),
  "component---src-pages-affiliate-mufg-bank-js": () => import("./../../../src/pages/affiliate/mufg-bank.js" /* webpackChunkName: "component---src-pages-affiliate-mufg-bank-js" */),
  "component---src-pages-affiliate-ramakrishna-cbse-js": () => import("./../../../src/pages/affiliate/ramakrishna-cbse.js" /* webpackChunkName: "component---src-pages-affiliate-ramakrishna-cbse-js" */),
  "component---src-pages-affiliate-ramakrishna-state-js": () => import("./../../../src/pages/affiliate/ramakrishna-state.js" /* webpackChunkName: "component---src-pages-affiliate-ramakrishna-state-js" */),
  "component---src-pages-affiliate-subrata-panda-js": () => import("./../../../src/pages/affiliate/subrata-panda.js" /* webpackChunkName: "component---src-pages-affiliate-subrata-panda-js" */),
  "component---src-pages-affiliate-tata-classedge-js": () => import("./../../../src/pages/affiliate/tata-classedge.js" /* webpackChunkName: "component---src-pages-affiliate-tata-classedge-js" */),
  "component---src-pages-affiliate-tata-world-js": () => import("./../../../src/pages/affiliate/tata-world.js" /* webpackChunkName: "component---src-pages-affiliate-tata-world-js" */),
  "component---src-pages-affiliate-videra-js": () => import("./../../../src/pages/affiliate/videra.js" /* webpackChunkName: "component---src-pages-affiliate-videra-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-explorestudi-js": () => import("./../../../src/pages/explorestudi.js" /* webpackChunkName: "component---src-pages-explorestudi-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plansandpricing-js": () => import("./../../../src/pages/plansandpricing.js" /* webpackChunkName: "component---src-pages-plansandpricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-blogs-index-js": () => import("./../../../src/pages/resources/blogs/index.js" /* webpackChunkName: "component---src-pages-resources-blogs-index-js" */),
  "component---src-pages-resources-media-index-js": () => import("./../../../src/pages/resources/media/index.js" /* webpackChunkName: "component---src-pages-resources-media-index-js" */),
  "component---src-pages-resources-media-inthenews-js": () => import("./../../../src/pages/resources/media/inthenews.js" /* webpackChunkName: "component---src-pages-resources-media-inthenews-js" */),
  "component---src-pages-resources-media-press-releases-js": () => import("./../../../src/pages/resources/media/press-releases.js" /* webpackChunkName: "component---src-pages-resources-media-press-releases-js" */),
  "component---src-pages-resources-videos-js": () => import("./../../../src/pages/resources/videos.js" /* webpackChunkName: "component---src-pages-resources-videos-js" */),
  "component---src-pages-studiform-js": () => import("./../../../src/pages/studiform.js" /* webpackChunkName: "component---src-pages-studiform-js" */),
  "component---src-pages-studilive-contactus-js": () => import("./../../../src/pages/studilive/contactus.js" /* webpackChunkName: "component---src-pages-studilive-contactus-js" */),
  "component---src-pages-studilive-courses-js": () => import("./../../../src/pages/studilive/courses.js" /* webpackChunkName: "component---src-pages-studilive-courses-js" */),
  "component---src-pages-studilive-faqs-js": () => import("./../../../src/pages/studilive/faqs.js" /* webpackChunkName: "component---src-pages-studilive-faqs-js" */),
  "component---src-pages-studilive-index-js": () => import("./../../../src/pages/studilive/index.js" /* webpackChunkName: "component---src-pages-studilive-index-js" */),
  "component---src-pages-studilive-termsandconditions-js": () => import("./../../../src/pages/studilive/termsandconditions.js" /* webpackChunkName: "component---src-pages-studilive-termsandconditions-js" */),
  "component---src-pages-studilive-thankyou-js": () => import("./../../../src/pages/studilive/thankyou.js" /* webpackChunkName: "component---src-pages-studilive-thankyou-js" */),
  "component---src-pages-studilive-webleads-1-js": () => import("./../../../src/pages/studilive/webleads1.js" /* webpackChunkName: "component---src-pages-studilive-webleads-1-js" */),
  "component---src-pages-tataclass-js": () => import("./../../../src/pages/tataclass.js" /* webpackChunkName: "component---src-pages-tataclass-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-affiliate-jsx": () => import("./../../../src/templates/affiliate.jsx" /* webpackChunkName: "component---src-templates-affiliate-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-board-jsx": () => import("./../../../src/templates/board.jsx" /* webpackChunkName: "component---src-templates-board-jsx" */),
  "component---src-templates-class-jsx": () => import("./../../../src/templates/class.jsx" /* webpackChunkName: "component---src-templates-class-jsx" */),
  "component---src-templates-d-2-c-jsx": () => import("./../../../src/templates/D2C.jsx" /* webpackChunkName: "component---src-templates-d-2-c-jsx" */),
  "component---src-templates-in-the-news-jsx": () => import("./../../../src/templates/InTheNews.jsx" /* webpackChunkName: "component---src-templates-in-the-news-jsx" */),
  "component---src-templates-pressrelease-jsx": () => import("./../../../src/templates/pressrelease.jsx" /* webpackChunkName: "component---src-templates-pressrelease-jsx" */)
}

